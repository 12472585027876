
body {
    @apply antialiased;
}

/* a {
    @apply underline transition-opacity duration-300;
}

a:hover {
    @apply opacity-50;
} */


.webkit-fill {
  height: 100vh;
  height: 100cqh;
}


/* .breatheId {
  @apply portrait:bg-[url('../images/BCB_CLOUDSportrait.svg')] landscape:bg-[url('../images/BCB_CLOUDS2.svg')];
} */

.fontTune {
  font-feature-settings: 'ss01';
}


/* .artText {
  color: chartreuse;
} */

.cloudAnim svg {
  @apply !w-full !h-auto;
}

.cloudAnim2 svg {
  @apply !w-full !h-auto;
}

.pathAnim svg {
  @apply !w-full portrait:!w-[135vw] !h-auto absolute bottom-0 right-0;
}


.bcbText svg {
  @apply !w-auto landscape:!max-w-[85vw] portrait:!max-w-[98vw] !h-auto !max-h-[52vh] ;
}

.sweatAnim svg {
  @apply !w-[272px] !h-auto ;
}


.colButt:nth-child(5) {
  @apply bg-pink-500;   
}

.prose img {
  @apply portrait:!max-h-[240px];
}

.prose ul, .prose li {
  @apply landscape:pl-0 portrait:pl-6;
}

li {
  list-style-type: none;
  position: relative;    /* It's needed for setting position to absolute in the next rule. */
}

li::before {
  content: '●';
  position: absolute;
  top: 0.05em; 
  left: -1.2em;          /* Adjust this value so that it appears where you want. */
  font-size: 0.9em;      /* Adjust this value so that it appears what size you want. */
}

/* ul {
 
  list-style-image: linear-gradient(to left bottom, red, blue);
} */

/* li {
  list-style-image: url('data:image/svg+xml,<svg width="100" height="100"><circle cx="50" cy="50" r="40" fill="yellow" /></svg>');
} */

/* li::marker{
  font-size: 1.5em;
  line-height: .1;
} */

/* menu stuff */

.menus.active {
  @apply translate-y-0;
}

.menuButt {
  @apply pointer-events-auto transition-all duration-100 px-8 py-4 landscape:border-[4px] portrait:border-[3px] border-black rounded-full  hover:text-black;
}





/* Checkbox slider */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/* purgecss start ignore */


/* ! special media queries! */

@media (aspect-ratio: 15/9) {
  .bcbText {
    background: chartreuse;
  }

  .artText {
    color: chartreuse;
  }
 
}

/* @media only all and (orientation:landscape) and (max-height:800px) { 
	
  .bcbText {
    @apply hidden; 
  }

  .bcbWideText {
    @apply !block ; 
  }

  #sunMenu, #sunClose {
    @apply !h-32; 
  }

  .menuButt {
    @apply !text-[24px] px-6 py-3 border-[2px]; 
  }


} */

.artButt:nth-child(3n+1) {
  @apply border-8 border-[#5F5FA6]  hover:bg-[#5F5FA6]  hover:text-white;
}

.artButt:nth-child(3n+2) {
  @apply border-8 border-[#3CBD6B]  hover:bg-[#3CBD6B]  hover:text-white;
}

.artButt:nth-child(3n+3) {
  @apply border-8 border-[#EA9D39]  hover:bg-[#EA9D39]  hover:text-white;
}

.artBanner {
  @apply transform -translate-y-40;
}


@media (min-aspect-ratio: 12/8) { 

  .artBanner {
    @apply: transform translate-y-0  !important;
  }
 

}

@media (min-aspect-ratio: 15/8) { 
	
  .bcbText {
    @apply hidden; 
  }



  .bcbWideText {
    @apply !block ; 
  }

  #sunMenu, #sunClose {
    @apply !h-32; 
  }

  .menuButt {
    @apply !text-[24px] px-6 py-3 border-[2px]; 
  }


}

/* ? other stuff below... */

#searchInput::placeholder {
  @apply landscape:text-base portrait:text-sm text-[#008EFF];
}

.twic-blur {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }

  
  .twic-done {
    opacity: 1;
  }

  .twic-done ~ .swipePic {
    @apply -translate-y-full; 
  }

  .singleResult {
    @apply cursor-pointer bg-[#008EFF] text-white px-5 py-3 rounded-full portrait:text-[14px];
  }

  .mapboxgl-canvas {
    @apply rounded-b-[1rem] rounded-t-[2rem];
  }


  .mapboxgl-popup-content {
    @apply bg-transparent shadow-none !important;
  }

  .mapboxgl-popup-content button {
    @apply hidden !important;
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    @apply hidden !important
  }


  /* override popup... */
  .mapboxgl-popup.mapboxgl-popup-anchor-bottom {
    @apply   !max-w-fit;
    /* transform: translate(-50%, -100%)!important; */
  }


/* purgecss end ignore */
