
@font-face {
    font-family: 'wally';
    font-weight: 500;
    font-style: normal;
    font-feature-settings: 'ss01';
    src:
        url(../fonts/GT-Walsheim-Medium.woff2) format("woff2"),
        url(../fonts/GT-Walsheim-Medium.woff) format("woff");
}

@font-face {
    font-family: 'wally';
    font-weight: 500;
    font-style: italic;
    font-feature-settings: 'ss01';
    src:
        url(../fonts/GT-Walsheim-Medium-Oblique.woff2) format("woff2"),
        url(../fonts/GT-Walsheim-Medium-Oblique.woff) format("woff");
}

@font-face {
    font-family: 'wally';
    font-weight: 700;
    font-style: normal;
    font-feature-settings: 'ss01';
    src:
        url(../fonts/GT-Walsheim-Bold.woff2) format("woff2"),
        url(../fonts/GT-Walsheim-Bold.woff) format("woff");
}

@font-face {
    font-family: 'wally';
    font-weight: 700;
    font-style: italic;
    font-feature-settings: 'ss01';
    src:
        url(../fonts/GT-Walsheim-Bold-Oblique.woff2) format("woff2"),
        url(../fonts/GT-Walsheim-Bold-Oblique.woff) format("woff");
}