@import "tailwindcss/base";
/* @import "./swiper.css"; */
@import "./typography.css";
@import "./map.css";
/* @import "./defaults.css"; */
@import "tailwindcss/components";


@import "./layout.css";
/* @import "./plyr2.css"; */
/* @import "./menu.css"; */



@import "tailwindcss/utilities";